import Head from 'next/head';
import Image from 'next/image';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { FabButtonAnimate } from '@/components/animate';
import useResponsive from '@/hooks/useResponsive';
import { StrongGreen } from '@/theme/OwnGlobalStylesComponents/StyleText';

import Pic404Desk from 'public/assets/images/404/404_desk.webp';
import Pic404Mobile from 'public/assets/images/404/404_responsive.webp';
import Logo99Min from 'public/assets/images/logos/logo_99minutos.svg';

const Page404 = () => {
  const isDesktop = useResponsive('up', 'md');
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <title> 404 No encontrado | 99Minutos</title>
      </Head>

      <BoxGeneral>
        <Box maxWidth={isDesktop ? '53%' : '100%'}>
          <Image
            src={isDesktop ? Pic404Desk : Pic404Mobile}
            style={{ objectFit: 'contain' }}
            alt="Caja vacía"
            loading="lazy"
          />
        </Box>
        <BoxTextContainer>
          <Image src={Logo99Min} alt="Logo 99Minutos" width={isDesktop ? 319 : 248} />

          <TextContainer>
            <StrongGreenModified>{t('404.1_TEXT_GREEN')}</StrongGreenModified>
            {t('404.2_TEXT_NORMAL')}
            <strong>{t('404.3_TEXT_STRONG')}</strong>
            {t('404.4_TEXT_NORMAL')}
            <br />
            <StrongGreenModified>{t('404.5_TEXT_GREEN')}</StrongGreenModified>
            {t('404.6_TEXT_NORMAL')}
          </TextContainer>

          <FabButtonAnimate data-cy="btn-back" variant="extended" size="medium" href="/">
            {t('404.BUTTON_BACK')}
          </FabButtonAnimate>
        </BoxTextContainer>
      </BoxGeneral>
    </>
  );
};

export default Page404;

const BoxGeneral = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  maxWidth: '100vw',
  maxHeight: '100vh',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const BoxTextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '43%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: '0 0.5rem',
    marginTop: '4.43rem',
  },
}));

const TextContainer = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle1,
  fontWeight: theme.typography.fontWeightRegular,
  color: theme.palette.secondary.main,
  textAlign: 'center',
  maxWidth: '20vw',
  marginTop: '2rem',
  marginBottom: '1.7rem',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100vw',
  },
}));

const StrongGreenModified = styled(StrongGreen)(({ theme }) => ({
  ...theme.typography.subtitle1,
  fontWeight: theme.typography.fontWeightMedium,
}));
